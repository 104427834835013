/* eslint-disable camelcase */
import ignoredMessages from './ignoredMessages';

const shouldIgnoreError = (error) => ignoredMessages.includes(error.message);

const errorHandler = error => shouldIgnoreError(error);

const excludeErrorsNewrelic = () => {
  if (window && window.newrelic) {
    window.newrelic.setErrorHandler(errorHandler);
  }
};

export default excludeErrorsNewrelic;
