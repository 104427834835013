/* eslint-disable max-len */
module.exports = {
  ragnar: {
    basePath: '/',
    server: {
      port: 8080,
      securePort: 8443,
      host: '0.0.0.0',
      static: './build',
    },
    middlewares: {
      csrf: true,
    },
    version: require(`${__dirname}/../package.json`).version,
  },
  assets: {
    prefix: '/',
  },
  services: {
    mercadoshops: {
      baseURL: 'http://internal.mercadoshops.com/',
      api: {
        shops: id => `/shops/${id}`,
        retries: {
          maxRetries: 4,
          delay: 500, // ms
          maxDelay: 5000, // ms
        },
      },
    },
    mercadolibre: {
      baseURL: 'https://internal-api.mercadolibre.com',
      timeout: 5000,
      api: {
        shops: {
          wrapper: {
            frontend: id => `/shops/frontend-wrapper/${id}`,
            admin: id => `/shops/${id}/admin-wrapper`,
          },
        },
        migration: shopId => `/shops/${shopId}/migration/status`,
        retries: {
          maxRetries: 4,
          minTimeout: 500, // ms
          maxTimeout: 5000, // ms
        },
      },
    },
    geolocation: {
      baseURL: 'https://internal-api.mercadolibre.com/',
      timeout: 2500,
      api: {
        forIP: ip => `geolocation/ip/${ip}`,
        retries: {
          maxRetries: 4,
          delay: 500, // ms
          maxDelay: 2000, // ms
        },
      },
    },
  },
  i18n: {
    app: 'fury_mshops-web-landing',
    project: 'mshopsweblanding',
    translationProject: 'meli',
    srcPath: `${__dirname}/../app`,
    extNames: ['js'],
  },
  pages: {
    migration: '/landing/migration',
    creation: '/landing/creation',
    loading: '/landing/loading',
    notAvailable: '/landing/not-available',
    oldAdmin: '/admin',
    activate: '/mercadoshops/activate',
    login: '/landing/login',
    privacy: '/landing/privacy',
    mailActivation: '/landing/mail-activation',
    mobileActivation: '/mercadoshops/mobile-activation',
    partners: '/landing/partners',
    mercadoEnvios: '/landing/mercado-envios',
    mercadoAds: '/landing/mercado-ads',
    mercadoLibre: '/landing/mercado-libre',
    mercadoPago: '/landing/mercado-pago',
    landing30: '/landing/landing30',
    mercadoCredits: '/landing/mercado-credits',
    accessDenied: '/mercadoshops/access-denied',
    rockyoursales: '/landing/rockeatusventas',
    experts: {
      ES: '/landing/expertos',
      BR: '/landing/especialistas',
    },
    ecosystem: {
      ES: '/landing/ecosistema',
      BR: '/landing/ecossistema',
    },
    ebooks: '/landing/ebooks',
    learn: '/landing/learning',
  },
  url: {
    mercadolibre: {
      MCO: 'https://www.mercadolibre.com.co',
      MLA: 'https://www.mercadolibre.com.ar',
      MLB: 'https://www.mercadolivre.com.br',
      MLC: 'https://www.mercadolibre.cl',
      MLM: 'https://www.mercadolibre.com.mx',
    },
    landing: {
      MCO: 'https://www.mercadoshops.com.co',
      MLA: 'https://www.mercadoshops.com.ar',
      MLB: 'https://www.mercadoshops.com.br',
      MLC: 'https://www.mercadoshops.cl',
      MLM: 'https://www.mercadoshops.com.mx',
    },
    mercadoshops: {
      hub: '/mercadoshops',
      welcome: '/mercadoshops/welcome',
    },
  },
  metrics: {
    googleTagManager: {
      MLA: 'GTM-TXJ4ZVG',
      MLB: 'GTM-TGD2C4W',
      MLM: 'GTM-P7T2M9Q',
      MLC: 'GTM-NCPFNCW',
      MCO: 'GTM-MRLCWVR',
    },
  },
  hotjar: {
    id: '1160754',
    traffic: { from: 0, to: 99 },
    tagPrefix: 'mshops-',
    tags: ['landing'],
  },
  webviewActivationEnabled: [
    'MLA',
    'MLB',
    'MLM',
    'MLC',
    'MCO',
  ],
};
